<template>
  <master-layout
    smallTitle="Farmacie nella tua"
    bigTitle="Area Geografica"
    :showInfoPopHover="true"
    :infoPopHover="
      whappyClientConfig.name == 'Yoube'
        ? INFOPOPOVER_MAP[whappyClientConfig.name]
        : INFOPOPOVER_MAP['default']
    "
  >
    <ion-grid class="ion-padding-top">
      <ion-row v-if="!anagProspectFilters">
        <ion-col> Caricamento dei filtri in corso... </ion-col>
      </ion-row>
      <ion-row v-else>
        <ion-col>
          <ion-select
            placeholder="Regione"
            interface="popover"
            v-model="anagProspectFiltersselected.regione"
            @ionChange="getAnagProspectFilters({ from: 'regione' })"
          >
            <ion-select-option
              v-for="regione in anagProspectFilters.regioni"
              :key="regione"
              :value="regione"
              >{{ regione }}</ion-select-option
            >
          </ion-select>
        </ion-col>
        <ion-col>
          <ion-select
            placeholder="Provincia"
            interface="popover"
            v-model="anagProspectFiltersselected.provincia"
            @ionChange="getAnagProspectFilters({ from: 'procinvia' })"
          >
            <ion-select-option
              v-for="provincia in anagProspectFilters.province"
              :key="provincia"
              :value="provincia"
              >{{ provincia }}</ion-select-option
            >
          </ion-select>
        </ion-col>
        <ion-col>
          <ion-select
            placeholder="Città"
            interface="popover"
            v-model="anagProspectFiltersselected.citta"
            @ionChange="getAnagProspectFilters({ from: 'main' })"
          >
            <ion-select-option
              v-for="citta in anagProspectFilters.citta"
              :key="citta"
              :value="citta"
              >{{ citta }}</ion-select-option
            >
          </ion-select>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-grid class="main-container">
      <!-- <ion-row
        class="items ion-padding-horizontal ion-margin-top"
        v-for="item of filteredAziende"
        :key="item.id"
        @click="openModalActualClient(item)"
      > -->
      <ion-row
        class="items ion-padding-horizontal ion-margin-top"
        v-for="item of filteredAziende"
        :key="item.id"
        @click="gotoDettagliCliente(item)"
      >
        <ion-col>
          <ion-row>
            <span class="font-bold"> {{ item.ragioneSociale }} </span>
          </ion-row>
          <ion-row> {{ item.citta }} {{ item.provincia }} </ion-row>
        </ion-col>
        <ion-col class="ion-text-end ion-padding-top" size="2">
          <ion-icon :icon="call"></ion-icon>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-row class="cta ion-justify-content-center"
      ><ion-col size="auto">
        <button
          :disabled="loadingMoreResults"
          type="button"
          class="p-d button-primary"
          @click="askForMoreResults"
        >
          {{ loadingMoreResults ? "Caricamento..." : "Carica più risultati" }}
        </button>
      </ion-col>
    </ion-row>
  </master-layout>
</template>

<script>
import {
  modalController,
  IonButton,
  IonFooter,
  IonToolbar,
  IonSearchbar,
  IonCol,
  IonGrid,
  IonRow,
  IonIcon,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue"
import { call } from "ionicons/icons"
import ModalActualClient from "./Modals/ModalActualClient.vue"
import GradientProgressBar from "@/components/GradientProgressBar.vue"
import ApiService from "../common/service.api"
export default {
  name: "AnagraficaClientiFarmacieAssegnate",
  components: {
    IonButton,
    IonFooter,
    IonToolbar,
    IonSearchbar,
    IonCol,
    IonGrid,
    IonRow,
    IonIcon,
    GradientProgressBar,
    IonSelect,
    IonSelectOption,
  },
  data() {
    return {
      call,
      filter: "",
      items: [],

      INFOPOPOVER_MAP: {
        Yoube: "Una lista di possibili clienti da contattare.",
        default: "Una lista di possibili clienti da contattare.",
      },
      currentResults: 0,
      loadingMoreResults: false,
      anagProspectFilters: null,
      anagProspectFiltersselected: {
        regione: "all",
        provincia: "all",
        citta: "all",
      },
    }
  },
  created() {
    this.getAnagProspectFilters({ from: "main" })
  },
  methods: {
    getAnagProspectFilters({ from }) {
      const url = `Ecommerce/GetAnagProspectFilters/${this.anagProspectFiltersselected.regione}/${this.anagProspectFiltersselected.provincia}`

      switch (from) {
        case "regione":
          this.anagProspectFiltersselected.provincia = "all"
          this.anagProspectFiltersselected.citta = "all"
          break
        case "provincia":
          this.anagProspectFiltersselected.citta = "all"
          break
        default:
          break
      }

      ApiService.get(url)
        .then((res) => {
          this.anagProspectFilters = res.data
        })
        .finally(() => {
          this.getData()
        })
    },
    async getData() {
      const { regione, provincia, citta } = this.anagProspectFiltersselected
      const endpoint = "Ecommerce/GetAnagsProspect/"
      const requestData = {
        take: 10,
        skip: 0,
        regione: regione === "all" ? "" : regione,
        provincia: provincia === "all" ? "" : provincia,
        citta: citta === "all" ? "" : citta,
      }

      try {
        const res = await ApiService.post(endpoint, requestData)
        this.items = res.data
      } catch (error) {
        console.error(error)
      } finally {
        this.currentResults = this.items.length
      }
    },
    async askForMoreResults() {
      const { regione, provincia, citta } = this.anagProspectFiltersselected
      const endpoint = "Ecommerce/GetAnagsProspect/"
      const requestData = {
        take: 10,
        skip: this.currentResults,
        regione: regione === "all" ? "" : regione,
        provincia: provincia === "all" ? "" : provincia,
        citta: citta === "all" ? "" : citta,
      }

      this.loadingMoreResults = true

      try {
        const res = await ApiService.post(endpoint, requestData)
        this.items = this.items.concat(res.data)
      } catch (error) {
        console.error(error)
      } finally {
        this.currentResults = this.items.length
        this.loadingMoreResults = false
      }
    },
    gotoDettagliCliente(cliente) {
      this.$router.push({
        name: "DettagliCliente",
        params: {
          idCliente: cliente.id,
        },
      })
    },
    async openModalActualClient(item) {
      const modal = await modalController.create({
        component: ModalActualClient,
        cssClass: "my-custom-class",
        componentProps: { client: item },
      })

      return modal.present()
    },
  },
  computed: {
    filteredAziende() {
      if (this.filter) {
        return this.items.filter((azienda) => {
          return (
            azienda.nome.toLowerCase().includes(this.filter.toLowerCase()) ||
            azienda.ragioneSociale
              .toLowerCase()
              .includes(this.filter.toLowerCase()) ||
            azienda.cognome.toLowerCase().includes(this.filter.toLowerCase())
          )
        })
      } else {
        return this.items
      }
    },
  },
}
</script>
